<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.log_delivery_status") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <v-simple-table
          fixed-header
          class="table-padding-2"
          height="calc(100vh - 250px)"
        >
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.time") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.delivery_state") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.delivery_partner_state") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.cod_2") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.content") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id" class="text-center">
                <td>{{ formatDateTime(item.action_time) }}</td>
                <td>{{ item.delivery_state_name }}</td>
                <td>{{ item.delivery_partner_state }}</td>
                <td>{{ formatNumber(item.money_collected) }}</td>
                <td>{{ item.message }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LogOrderDeliveryStatus",
  props: {
    idOrder: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async getList() {
      const { data } = await httpClient.post("/get-order-delivery-status-log", {
        id_order: this.idOrder,
      });
      this.items = [...data];
    },
  },
};
</script>

<style scoped></style>
